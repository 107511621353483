<template>
    <div>
        <van-nav-bar
        title="编辑个人资料"
        left-text="取消"
        right-text="保存"
        @click-left="onClickLeft"
        @click-right="onClickRight"
        />

        <van-form  ref='infoForm' input-align="right" label-width='80'  :show-error-message='true' :scroll-to-error='true'>
            <van-field name="uploader" label="头像" style="margin: 20px 0;">
                <template #input>
                    <van-uploader v-model="fileList" :max-count="1" :after-read="fileChange"/>
                </template>
            </van-field>
            <van-field
                v-model="infoForm.nickname"
                name="姓名"
                label="姓名"
                placeholder="请填写姓名"
            />
            <van-field
                v-model="infoForm.username"
                name="账号"
                label="账号"
                placeholder="账号"
                readonly
            />
            <van-field
                    readonly
                    clickable
                    is-link
                    name="picker"
                    :value="infoForm.sexName"
                    label="性别"
                    placeholder="请点击选择"
                    @click="onShowSexSelect"
            />
            <van-popup v-model="sexshowPicker" position="bottom">
                <van-picker
                    v-model="infoForm.sex"
                    show-toolbar
                    :columns="sexList"
                    @confirm="sexfirm"
                    @cancel="sexshowPicker = false"
                />
            </van-popup>
            <van-field
                readonly
                clickable
                is-link
                name="datetimePicker"
                :value="infoForm.birthday"
                label="生日"
                placeholder="点击选择时间"
                @click="birthdayshowPicker = true"
            />
            <van-popup v-model="birthdayshowPicker" position="bottom">
                <van-datetime-picker
                    type="date"
                    @confirm="birthdayConfirm"
                    @cancel="birthdayshowPicker = false"
                    :formatter="formatter"
                />
            </van-popup>
            
            <van-field
                v-model="infoForm.mobile"
                label="手机"
                type="tel"
                placeholder="请输入手机号"
            />
            <van-field
                v-model="infoForm.email"
                label="邮箱"
                placeholder="请输入邮箱"
            />
            <!-- <van-field
                v-model="infoForm.address"
                label="地址"
                placeholder="请输入地址"
            /> -->
            <van-field
                readonly
                clickable
                is-link
                name="addressPicker"
                :value="infoForm.addressName"
                label="地址"
                placeholder="点击选择地址"
                @click="onShowSelectAddress"
            />
            <van-popup v-model="addressshowPicker" position="bottom">
              <van-area title="地址" 
              v-model="infoForm.code"
              :area-list="areaList"
              :columns-placeholder="['请选择', '请选择', '请选择']"   
              @confirm="addressConfirm"
              @cancel="addressCancel"/> 
           </van-popup>
           <van-field
                v-model="infoForm.detailedAddress "
                name="详细地址"
                label="详细地址"
                placeholder="请填写详细地址"
            />
            <!-- <van-field
                v-model="infoForm.sms"
                center
                clearable
                label="短信验证码"
                placeholder="请输入短信验证码"
                required
                :rules="[{ required: true}]"
                >
                <template #button>
                    <van-button size="small" type="primary" style="width:100px;height:30px;line-height: 30px;">发送验证码</van-button>
                </template>
            </van-field> -->
        </van-form>
   
    </div>
</template>
<script>
import func from '@/lib/commonfun';
import { userinfoUpdate,avatarUpdate,getuserInfo } from  "@/api/base";
import { Notify } from 'vant';
import { areaList } from '@vant/area-data';
export default {
  name: 'myinfo',
  data(){
    return {
        infoForm:{
           nickname:'',//姓名
           sex:'',
           sexName: "",
           birthday:'',
           account:'',
           email:'',//邮箱
           mobile:'',//手机号
           birthday:'',
           avatar:'',//头像
           address:'',
           addressName: '',
           detailedAddress:'',//详细地址
        },
        areaList,
        sexList:[{text:'',value:0},{text:'男',value:1},{text:'女',value:2}],
        sexshowPicker:false,
        birthdayshowPicker:false,
        fileList: [
        { url: '' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        // { url: 'https://cloud-image', isImage: true },
        ],
        addressshowPicker:false
    }
  },

  mounted(){
    // this.infoForm.nickname=sessionStorage.getItem("nickname");
    console.log(this.areaList)
    this.getuserinfoFun();
  },
  methods:{
    onShowSexSelect() {
      console.log(this.infoForm)
      this.sexshowPicker = true
      // this.infoForm.sex
    },
    getuserinfoFun(){
      getuserInfo().then((res)=>{
        if(res.code==0){  
          this.infoForm=res.data;
          if(this.infoForm.sex){
            let sexList={1:'男',2:'女',0:''}
            this.infoForm.sex = this.infoForm.sex + ''
            this.infoForm.sexName=sexList[this.infoForm.sex];
          }
          if(this.infoForm.birthday){
            this.infoForm.birthday=func.timestampToYMD(this.infoForm.birthday)
          }
          if(this.infoForm.avatar){
            this.$set(this.fileList[0],'url',this.infoForm.avatar)
          }
          if(res.data.memberCardRespVo.address){
            this.infoForm.address=res.data.memberCardRespVo.address
            let addressCode = this.infoForm.address
            let addressCodeList = (addressCode+'').replace(/(.{6})/g, "$1 ").split(' ')
            this.$set(this.infoForm, 'code', addressCodeList[addressCodeList.length - 2])
            let province =  addressCodeList[0]?this.areaList.province_list[addressCodeList[0]-0]:''
            let city =  addressCodeList[1]?this.areaList.city_list[addressCodeList[1]-0]:''
            let county =  addressCodeList[2]?this.areaList.county_list[addressCodeList[2]-0]:''
            this.infoForm.addressName = province+city+county
          }
        }else{
          Notify({ type: 'warning', message: res.msg });
        }
      },(err)=>{
        Notify({ type: 'warning', message: '网络错误'});
      })
    },
    cellBind(e){
        console.log(e)
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    onClickRight(){
     let params={...this.infoForm}
     params.birthday=new Date(params.birthday).getTime();
     delete params.sexName;
     userinfoUpdate(params).then((res)=>{
        if(res.code==0){
            Notify({ type: 'success', message:'修改成功!'});
            this.$router.go(-1);
            // this.$router.push({path:'/logon'})
        }else{
            Notify({ type: 'warning', message: res.msg });
        }
      })
    //  this.$refs.infoForm.validate().then((res)=>{
    //     userinfoUpdate(params).then((res)=>{
    //         if(res.code==0){
    //             Notify({ type: 'success', message:'修改成功!' });
    //             this.$router.go(-1)
    //         }else{
    //             Notify({ type: 'warning', message: res.msg });
    //         }
    //     })
    //  })
    //  this.$refs.infoForm.submit();
    },
    logoutBind(){
        this.$router.push({name:'logon'})
    },
    sexfirm(val){
      console.log(val)
      this.sexshowPicker=false;
      this.infoForm.sexName=val.text;
      this.infoForm.sex=val.value;
    },
    formatter(type, value){
        if (type === 'year') {
          return `${value}年`;
        } else if (type === 'month') {
          return `${value}月`;
        } else if (type === 'day') {
           return `${value}日`;
        }
        console.log(value)
        return value;
    },
    // 文件上传
    fileChange(file){
      let formData=new FormData();
      formData.append('avatarFile',file.file)
      avatarUpdate(formData).then((res)=>{
           if(res.code==0){
                Notify({ type: 'warning', message:'上传成功!' });
                this.infoForm.avatar=res.data
                if(res.data){
                    sessionStorage.setItem("avatar",res.data);
                }
            }else{
              Notify({ type: 'warning', message: res.msg });
            }
      },(err)=>{
        Notify({ type: 'warning', message: '网路错误!' });
      })
    },
    birthdayConfirm(val){
        this.birthdayshowPicker=false;
        this.infoForm.birthday= func.formatDate(val);
    },
    onShowSelectAddress() {
      this.addressshowPicker = true
      let addressCode = this.infoForm.address
      console.log(addressCode)
       if(!addressCode)return
       let addressCodeList = (addressCode+'').replace(/(.{6})/g, "$1 ").split(' ')
       console.log(addressCodeList)
       this.$set(this.infoForm, 'code', addressCodeList[addressCodeList.length - 2])
    },
    addressCancel(){
       this.addressshowPicker = false
       this.$set(this.infoForm, 'code', '')
    },
    addressConfirm(val){
      console.log(val)
      this.addressshowPicker = false
      let economize = this.getCodeAndName(val[0]);
      let market = this.getCodeAndName(val[1]);
      let distinguish = this.getCodeAndName(val[2]);
      this.infoForm.address = economize.code + market.code + distinguish.code;
      this.infoForm.addressName = economize.name + market.name + distinguish.name;
    },
    getCodeAndName(val) {
      return val ? {code: val.code, name: val.name} : {code: '', name: ''};
    }
  }
}
</script>
<style scoped>
   .footBox{
    position: fixed;
    bottom: 20px;
    width:100%;
    text-align: center;
   }
   .van-button{
      width:80%;
      height:40px;
      border-radius: 10px;
   }
</style>