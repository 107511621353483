export default{
    // 获取时间
  getNowFormatDate() {
    var date = new Date()
    var seperator1 = '-'
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var strDate = date.getDate()
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate
    return currentdate
  },
  formatDate(date) {
    // 将日期对象转换为"年-月-日"的格式
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  },
  timestampToYMD(timestamp) {
    // 将时间戳转换为 Date 对象
    var date = new Date(timestamp);

    // 获取年、月、日
    var year = date.getFullYear();
    var month = date.getMonth() + 1;  // JavaScript 的月份从 0 开始
    var day = date.getDate();

    // 如果月或日的数字小于 10，前面添加一个 '0'
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // 返回格式化的日期字符串
    return year + '-' + month + '-' + day;
  }

}